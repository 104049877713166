import React, { useCallback, useState } from "react";
import {
  Row,
  Col,
} from "reactstrap";
import { Right } from "../../../constant";


import  TicketDetails from "./ticketdetailsnew";

export const NewTicketDetails = React.forwardRef((props, ref) => {
  const [activeTab, setActiveTab] = useState(null);

  const {
    closeCustomizer1,
    isTicketDetailsOpen,
    isSessionHistoryOpen,
    selectedRow,
    RefreshHandler,
    detailsUpdate,
  } = props;

  const isOpen = isTicketDetailsOpen || isSessionHistoryOpen ? ' open' : '';

  const handleClose = useCallback(() => {
    setActiveTab(null);
    closeCustomizer1();
  }, [closeCustomizer1]);

  return (
    <React.Fragment>
      <Row>
        <Col md="12">
          <div className={`customizer-contain${isOpen}`} ref={ref}>
            <div className="tab-content" id="c-pills-tabContent">
              <div className="customizer-header">
                <br />
                <i className="icon-close" onClick={handleClose}></i>
                <br />
               
              </div>
              <div className="customizer-body custom-scrollbar">
                {
                  (selectedRow && isTicketDetailsOpen) && (
                    <>
                      <div id="headerheading">
                        Lead Information :
                        T{selectedRow.id}
                      </div>
                      <TicketDetails
                        lead={selectedRow}
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                        onUpdate={(data) => detailsUpdate(data)}
                        detailsUpdate={detailsUpdate}
                        closeCustomizer1={closeCustomizer1}
                        Refreshhandler={RefreshHandler}
                      />
                    </>
                  )
                }
               
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
});
