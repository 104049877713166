export const HANDLE_CHANGE_FORM_INPUT = "HANDLE_CHANGE_FORM_INPUT";
export const HANDLE_CHANGE_FORM_INPUT1 = "HANDLE_CHANGE_FORM_INPUT1";
export const HANDLE_CHANGE_FORM_INPUT2 = "HANDLE_CHANGE_FORM_INPUT2";



export const TOGGLE_IMAGE_UPLOAD_MODAL = "TOGGLE_IMAGE_UPLOAD_MODAL";
export const TOGGLE_IMAGE_UPLOAD_MODAL1 = "TOGGLE_IMAGE_UPLOAD_MODAL1";
export const TOGGLE_IMAGE_UPLOAD_MODAL2= "TOGGLE_IMAGE_UPLOAD_MODAL2";


export const HANDLE_CHANGE_DISPLAY_IMAGE_INPUT = "HANDLE_CHANGE_DISPLAY_IMAGE_INPUT";
export const HANDLE_CHANGE_DISPLAY_IMAGE_INPUT1 = "HANDLE_CHANGE_DISPLAY_IMAGE_INPUT1";
export const HANDLE_CHANGE_DISPLAY_IMAGE_INPUT2 = "HANDLE_CHANGE_DISPLAY_IMAGE_INPUT2";




export const SET_BILLING_ADDRESS_AS_PERMANENT_ADDRESS = "SET_BILLING_ADDRESS_AS_PERMANENT_ADDRESS";

export const TOGGLE_SAVE_AS_BUTTON = "TOGGLE_SAVE_AS_BUTTON";

export const SHOW_HIDE_PERMANENT_ADDRESS = "SHOW_HIDE_PERMANENT_ADDRESS";

export const SET_ACTIVE_ADDRESS_BREADCRUMB = "SET_ACTIVE_ADDRESS_BREADCRUMB";

export const SET_TOGGLE_STATE_FOR_SAVE_AS_BILLING_ADDRESS = "SET_TOGGLE_STATE_FOR_SAVE_AS_BILLING_ADDRESS";

export const SET_SERVICE = "SET_SERVICE";

export const CLEAR_SERVICE = "CLEAR_SERVICE";

export const OPEN_CUSTOMIZER = "OPEN_CUSTOMIZER";

export const CLOSE_CUSTOMIZER = "CLOSE_CUSTOMIZER";

export const SET_SELECTED_PLAN = "SET_SELECTED_PLAN";

export const CLEAR_SELECTED_PLAN = "CLEAR_SELECTED_PLAN";

export const TOGGLE_SIGNATURE_UPLOAD_SIDE_BAR = "TOGGLE_SIGNATURE_UPLOAD_SIDE_BAR";

export const SET_ERRORS = "SET_ERRORS";

export const SET_FORM_DATA = "SET_FORM_DATA";

export const SET_ZONE_BY_BRANCH = "SET_ZONE_BY_BRANCH";

export const SET_AREA_BY_ZONE = "SET_AREA_BY_ZONE";

export const SET_DUE_DATE = "SET_DUE_DATE";

export const SET_START_DATE = "SET_START_DATE";