import React from "react";
export const userStatus = [
    {
      id: true,
      name: "Active",
    },
    {
      id: false,
      name: "InActive",
    },
  ];